<script>
export default {
  setup() {
    const auth = useAuthStore();
    const layout = useLayoutStore();
    const config = useConfigurationStore();
    return { auth, layout, config };
  },
  computed: {
    ...mapGetters(useAuthStore, ["hasSession"]),
    ...mapGetters(useResponseStore, ["htmlError", "errors"]),
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<template>
  <footer class="uk-section-secondary uk-light">
    <div class="uk-container uk-section">
      <div class="uk-grid" data-uk-grid>
        <div class="uk-width-1-3@s">
          <div class="uk-margin uk-flex uk-flex-middle">
            <h2 class="uk-margin-remove font-weight-700">
              {{ config.get("app.name") }}
            </h2>
            <div class="uk-margin-left uk-hidden@s">
              <a
                target="_blank"
                href="#"
                class="uk-icon-button uk-margin-small-right"
                data-uk-icon="youtube"
              ></a>
              <a
                target="_blank"
                href="#"
                class="uk-icon-button uk-margin-small-right"
                data-uk-icon="facebook"
              ></a>
              <a
                target="_blank"
                href="#"
                class="uk-icon-button"
                data-uk-icon="instagram"
              ></a>
            </div>
          </div>
          <div class="uk-text-justify font-14">
            {{ layout.appDesc }}
          </div>
          <div class="uk-margin-top uk-visible@s">
            <a
              target="_blank"
              href="#"
              class="uk-icon-button uk-margin-small-right"
              data-uk-icon="youtube"
            ></a>
            <a
              target="_blank"
              href="#"
              class="uk-icon-button uk-margin-small-right"
              data-uk-icon="facebook"
            ></a>
            <a
              target="_blank"
              href="#"
              class="uk-icon-button"
              data-uk-icon="instagram"
            ></a>
          </div>
        </div>

        <div class="uk-width-2-3@s uk-grid-small" uk-grid>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-4@s">&nbsp;</div>
            <div class="uk-width-3-4@s uk-grid-small" uk-grid>
              <div class="uk-width-1-3@s uk-visible@s uk-margin-top">
                <h5 class="font-weight-700">{{ $t("Main Menu") }}</h5>
                <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                  <RouterLink class="font-14 uk-margin-small" to="/">
                    {{ $t("Home") }}
                  </RouterLink>
                </div>
              </div>
              <div class="uk-width-2-3@s uk-margin-top">
                <div class="uk-grid-small" uk-grid>
                  <!-- <h5 class="uk-visible@s font-weight-700">
                                        {{ config.get('app.name') }}
                                    </h5> -->
                  <div class="uk-width-1-2 uk-width-1-1@s">
                    <img src="/images/logo.png" class="ids-home-logo" />
                  </div>
                  <div class="uk-width-1-2 uk-hidden@s">
                    <!-- <h5 class="font-weight-700">{{ config.get('app.name') }}</h5> -->
                  </div>
                  <div class="uk-width-1-2 uk-width-1-1@s uk-visible@s">
                    <div class="font-14">
                      Jl. Kiara Sari Permai No.2, Margasari, Kec. Buahbatu. Kota
                      Bandung, Jawa Barat 40186, Indonesia
                    </div>
                  </div>
                  <div class="uk-hidden@s uk-width-1-1 uk-text-center">
                    Jl. Kiara Sari Permai No.2, Margasari, Kec. Buahbatu. Kota
                    Bandung, Jawa Barat 40186, Indonesia
                  </div>
                </div>
                <!-- <div class="uk-margin-small-top">
                                    <div>
                                        <label class="uk-form-label">Telepon :</label>
                                        (0717) 439302
                                    </div>
                                    <div>
                                        <label class="uk-form-label">Faximile :</label>
                                        (0717) 439302
                                    </div>
                                    <div>
                                        <label class="uk-form-label">Email :</label>
                                        info@babelprov.go.id
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-section uk-section-xsmall"
      style="background-color: rgba(0, 0, 0, 0.15)"
    >
      <div class="uk-container">
        <div
          class="uk-grid-small uk-child-width-1-2@s uk-text-center uk-text-left@s"
          uk-grid
        >
          <div class="uk-text-small uk-text-muted">
            © {{ year }} - {{ config.get("app.name") }} | All Rights Reserved.
          </div>
          <div
            class="uk-text-small uk-text-muted uk-text-center uk-text-right@s"
          >
            Powered by <a href="https://idsolutions.id">ID Solutions</a> | Built
            with
            <HeartIcon />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
