export const usePusherStore = defineStore('pusher', {
    state: () => ({
        stream: null
    }),

    actions: {
        registerConnection() {
            let enabledTransports = ['wss', 'ws'];
            let forceTLS = true;
            let useTLS = true;
            const auth = useAuthStore();
            const scheme = import.meta.env.VITE_PUSHER_SCHEME ?? 'https';
            if (scheme != 'https') {
                enabledTransports = ['ws'];
                forceTLS = false;
                useTLS = false;
            }
            if (!this.stream)
                this.stream = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
                    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                    wsHost: import.meta.env.VITE_PUSHER_HOST,
                    wsPort: import.meta.env.VITE_PUSHER_PORT,
                    wssHost: import.meta.env.VITE_PUSHER_HOST,
                    wssPort: import.meta.env.VITE_PUSHER_PORT,
                    useTLS: useTLS,
                    forceTLS: forceTLS,
                    encrypted: true,
                    disableStats: true,
                    enabledTransports: enabledTransports,
                    channelAuthorization: {
                        endpoint: "/broadcasting/auth",
                        headers: { "X-Authorization": "Bearer " + auth.token },
                    }
                });
        },
        unRegisterChannel(payload) {
            this.stream.unsubscribe(payload);
        },
        registerChannel(payload) {
            this.stream.subscribe(payload);
        },
    },
});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePusherStore, import.meta.hot))
}
