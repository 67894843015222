export const useHelperStore = defineStore('helper', {
    state: () => ({
        name: null,
    }),

    actions: {
        translateObjectKey(obj) {
            const { t } = useI18n();
            let exportFields = {};

            Object.keys(obj).forEach(key => {
                exportFields[t(key)] = obj[key];
            });

            return exportFields;
        },
        exportFieldsHeader(inputArray) {
            let exportFields = {};
            const { t } = useI18n();

            inputArray.forEach(item => {
                const key = Object.keys(item)[0];
                const value = t(item[key]);
                exportFields[value] = key;
            });

            return exportFields;
        },
        sortableHeader(header) {
            return header
                .filter(obj => {
                    const keys = Object.keys(obj);
                    const firstKey = keys[0];

                    // Check conditions
                    return !firstKey.includes('.');
                })
                .map(obj => Object.keys(obj)[0]);
        },
        searchableHeader(header) {
            return header
                .filter(obj => {
                    const keys = Object.keys(obj);
                    const firstKey = keys[0];

                    // Check conditions
                    return !firstKey.startsWith('is') &&
                        !firstKey.startsWith('has') &&
                        !firstKey.startsWith('can');
                })
                .map(obj => Object.keys(obj)[0]);
        },
        filterData(obj, propertiesToShow) {
            const filteredData = {};

            propertiesToShow.forEach(property => {
                if (obj.hasOwnProperty(property)) {
                    filteredData[property] = obj[property];
                }
            });

            return filteredData;
        },
        capitalize(string) {
            return _.capitalize(string);
        },
        headline(str) {
            if (str == null) return '';
            str = str.replaceAll('-', ' ').replaceAll('_', ' ');
            str = str.replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^./, function (match) {
                    return match.toUpperCase();
                });
            str.toLowerCase();
            return str.replace(/\b\w/g, function (match) {
                return match.toUpperCase();
            });
        },
        parseUrl(input) {
            const params = new URLSearchParams();
            for (const key in input) {
                if (Array.isArray(input[key])) {
                    input[key].forEach(val => {
                        if (val != null || val != "null")
                            params.append(key + '[]', val)
                    })
                } else {
                    params.append(key, input[key]);
                }
            }

            return "?" + params.toString();
        },
        sort(valuePath, array) {
            let path = valuePath.split('.')

            return array.sort((a, b) => {
                return getValue(b, path) - getValue(a, path)
            });

            function getValue(obj, path) {
                path.forEach(path => obj = obj[path])
                return obj;
            }
        },
        numberFormat(number, decimals = 0, dec_point = ".", thousands_sep = ",") {
            let n = number,
                c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals,
                d = dec_point == undefined ? "." : dec_point,
                t = thousands_sep == undefined ? "," : thousands_sep,
                s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = i.length > 3 ? i.length % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },

        initialName(name, length = 2, uppercase = false, rtl = false) {
            this.setName(name);

            const words = name.replace(/[^A-Za-z0-9.!?\s]/, '').split(' ');

            // If the name contains a single word, use first N characters
            if (words.length === 1) {
                return this.getInitialFromOneWord(words, length, uppercase);
            } else {
                return this.getInitialFromMultipleWords(words, length, uppercase).replace(/[^A-Za-z0-9]/, '');
            }
        },
        setName(name) {
            if (Array.isArray(name)) {
                throw new Error('Passed value cannot be an array');
            } else if (typeof name === 'object' && typeof name.toString !== 'function') {
                throw new Error('Passed object must have a toString method');
            }

            if (typeof name === 'string' && name.includes('@')) {
                name = name.split('@')[0].replace('.', ' ');
            }

            this.name = name;
        },

        getInitialFromOneWord(words, length, uppercase) {
            let initial = words[0];

            if (this.name.length >= length) {
                initial = this.name.substring(0, length);
            }

            return uppercase ? initial.toUpperCase() : initial;
        },

        getInitialFromMultipleWords(words, length, uppercase) {
            // Otherwise, use the initial character from each word
            const initials = words.map((word) => word.substring(0, 1));
            return this.selectInitialFromMultipleInitials(initials, length, uppercase);
        },

        selectInitialFromMultipleInitials(initials, length, uppercase) {
            let initial = initials.slice(0, length).join('');

            if (initial.length < length) {
                const rest = length - initial.length;
                initial += this.name.substr(-rest, rest);
            }
            return uppercase ? initial.toUpperCase() : initial;
        },

        getLocaleName(code) {
            const locales = [
                {
                    "code": "ab",
                    "name": "Abkhazian"
                },
                {
                    "code": "aa",
                    "name": "Afar"
                },
                {
                    "code": "af",
                    "name": "Afrikaans"
                },
                {
                    "code": "ak",
                    "name": "Akan"
                },
                {
                    "code": "sq",
                    "name": "Albanian"
                },
                {
                    "code": "am",
                    "name": "Amharic"
                },
                {
                    "code": "ar",
                    "name": "Arabic"
                },
                {
                    "code": "an",
                    "name": "Aragonese"
                },
                {
                    "code": "hy",
                    "name": "Armenian"
                },
                {
                    "code": "as",
                    "name": "Assamese"
                },
                {
                    "code": "av",
                    "name": "Avaric"
                },
                {
                    "code": "ae",
                    "name": "Avestan"
                },
                {
                    "code": "ay",
                    "name": "Aymara"
                },
                {
                    "code": "az",
                    "name": "Azerbaijani"
                },
                {
                    "code": "bm",
                    "name": "Bambara"
                },
                {
                    "code": "ba",
                    "name": "Bashkir"
                },
                {
                    "code": "eu",
                    "name": "Basque"
                },
                {
                    "code": "be",
                    "name": "Belarusian"
                },
                {
                    "code": "bn",
                    "name": "Bengali"
                },
                {
                    "code": "bi",
                    "name": "Bislama"
                },
                {
                    "code": "bs",
                    "name": "Bosnian"
                },
                {
                    "code": "br",
                    "name": "Breton"
                },
                {
                    "code": "bg",
                    "name": "Bulgarian"
                },
                {
                    "code": "my",
                    "name": "Burmese"
                },
                {
                    "code": "ca",
                    "name": "Catalan Valencian"
                },
                {
                    "code": "ch",
                    "name": "Chamorro"
                },
                {
                    "code": "ce",
                    "name": "Chechen"
                },
                {
                    "code": "ny",
                    "name": "Chichewa Chewa Nyanja"
                },
                {
                    "code": "zh",
                    "name": "Chinese"
                },
                {
                    "code": "cu",
                    "name": "Church Slavonic Old Slavonic Old Church Slavonic"
                },
                {
                    "code": "cv",
                    "name": "Chuvash"
                },
                {
                    "code": "kw",
                    "name": "Cornish"
                },
                {
                    "code": "co",
                    "name": "Corsican"
                },
                {
                    "code": "cr",
                    "name": "Cree"
                },
                {
                    "code": "hr",
                    "name": "Croatian"
                },
                {
                    "code": "cs",
                    "name": "Czech"
                },
                {
                    "code": "da",
                    "name": "Danish"
                },
                {
                    "code": "dv",
                    "name": "Divehi Dhivehi Maldivian"
                },
                {
                    "code": "nl",
                    "name": "Dutch Flemish"
                },
                {
                    "code": "dz",
                    "name": "Dzongkha"
                },
                {
                    "code": "en",
                    "name": "English"
                },
                {
                    "code": "eo",
                    "name": "Esperanto"
                },
                {
                    "code": "et",
                    "name": "Estonian"
                },
                {
                    "code": "ee",
                    "name": "Ewe"
                },
                {
                    "code": "fo",
                    "name": "Faroese"
                },
                {
                    "code": "fj",
                    "name": "Fijian"
                },
                {
                    "code": "fi",
                    "name": "Finnish"
                },
                {
                    "code": "fr",
                    "name": "French"
                },
                {
                    "code": "fy",
                    "name": "Western Frisian"
                },
                {
                    "code": "ff",
                    "name": "Fulah"
                },
                {
                    "code": "gd",
                    "name": "Gaelic Scottish Gaelic"
                },
                {
                    "code": "gl",
                    "name": "Galician"
                },
                {
                    "code": "lg",
                    "name": "Ganda"
                },
                {
                    "code": "ka",
                    "name": "Georgian"
                },
                {
                    "code": "de",
                    "name": "German"
                },
                {
                    "code": "el",
                    "name": "Greek Modern (1453–)"
                },
                {
                    "code": "kl",
                    "name": "Kalaallisut Greenlandic"
                },
                {
                    "code": "gn",
                    "name": "Guarani"
                },
                {
                    "code": "gu",
                    "name": "Gujarati"
                },
                {
                    "code": "ht",
                    "name": "Haitian Haitian Creole"
                },
                {
                    "code": "ha",
                    "name": "Hausa"
                },
                {
                    "code": "he",
                    "name": "Hebrew"
                },
                {
                    "code": "hz",
                    "name": "Herero"
                },
                {
                    "code": "hi",
                    "name": "Hindi"
                },
                {
                    "code": "ho",
                    "name": "Hiri Motu"
                },
                {
                    "code": "hu",
                    "name": "Hungarian"
                },
                {
                    "code": "is",
                    "name": "Icelandic"
                },
                {
                    "code": "io",
                    "name": "Ido"
                },
                {
                    "code": "ig",
                    "name": "Igbo"
                },
                {
                    "code": "id",
                    "name": "Indonesia"
                },
                {
                    "code": "ia",
                    "name": "Interlingua"
                },
                {
                    "code": "ie",
                    "name": "Interlingue Occidental"
                },
                {
                    "code": "iu",
                    "name": "Inuktitut"
                },
                {
                    "code": "ik",
                    "name": "Inupiaq"
                },
                {
                    "code": "ga",
                    "name": "Irish"
                },
                {
                    "code": "it",
                    "name": "Italian"
                },
                {
                    "code": "ja",
                    "name": "Japanese"
                },
                {
                    "code": "jv",
                    "name": "Javanese"
                },
                {
                    "code": "kn",
                    "name": "Kannada"
                },
                {
                    "code": "kr",
                    "name": "Kanuri"
                },
                {
                    "code": "ks",
                    "name": "Kashmiri"
                },
                {
                    "code": "kk",
                    "name": "Kazakh"
                },
                {
                    "code": "km",
                    "name": "Central Khmer"
                },
                {
                    "code": "ki",
                    "name": "Kikuyu Gikuyu"
                },
                {
                    "code": "rw",
                    "name": "Kinyarwanda"
                },
                {
                    "code": "ky",
                    "name": "Kirghiz Kyrgyz"
                },
                {
                    "code": "kv",
                    "name": "Komi"
                },
                {
                    "code": "kg",
                    "name": "Kongo"
                },
                {
                    "code": "ko",
                    "name": "Korean"
                },
                {
                    "code": "kj",
                    "name": "Kuanyama Kwanyama"
                },
                {
                    "code": "ku",
                    "name": "Kurdish"
                },
                {
                    "code": "lo",
                    "name": "Lao"
                },
                {
                    "code": "la",
                    "name": "Latin"
                },
                {
                    "code": "lv",
                    "name": "Latvian"
                },
                {
                    "code": "li",
                    "name": "Limburgan Limburger Limburgish"
                },
                {
                    "code": "ln",
                    "name": "Lingala"
                },
                {
                    "code": "lt",
                    "name": "Lithuanian"
                },
                {
                    "code": "lu",
                    "name": "Luba-Katanga"
                },
                {
                    "code": "lb",
                    "name": "Luxembourgish Letzeburgesch"
                },
                {
                    "code": "mk",
                    "name": "Macedonian"
                },
                {
                    "code": "mg",
                    "name": "Malagasy"
                },
                {
                    "code": "ms",
                    "name": "Malay"
                },
                {
                    "code": "ml",
                    "name": "Malayalam"
                },
                {
                    "code": "mt",
                    "name": "Maltese"
                },
                {
                    "code": "gv",
                    "name": "Manx"
                },
                {
                    "code": "mi",
                    "name": "Maori"
                },
                {
                    "code": "mr",
                    "name": "Marathi"
                },
                {
                    "code": "mh",
                    "name": "Marshallese"
                },
                {
                    "code": "mn",
                    "name": "Mongolian"
                },
                {
                    "code": "na",
                    "name": "Nauru"
                },
                {
                    "code": "nv",
                    "name": "Navajo Navaho"
                },
                {
                    "code": "nd",
                    "name": "North Ndebele"
                },
                {
                    "code": "nr",
                    "name": "South Ndebele"
                },
                {
                    "code": "ng",
                    "name": "Ndonga"
                },
                {
                    "code": "ne",
                    "name": "Nepali"
                },
                {
                    "code": "no",
                    "name": "Norwegian"
                },
                {
                    "code": "nb",
                    "name": "Norwegian Bokmål"
                },
                {
                    "code": "nn",
                    "name": "Norwegian Nynorsk"
                },
                {
                    "code": "ii",
                    "name": "Sichuan Yi Nuosu"
                },
                {
                    "code": "oc",
                    "name": "Occitan"
                },
                {
                    "code": "oj",
                    "name": "Ojibwa"
                },
                {
                    "code": "or",
                    "name": "Oriya"
                },
                {
                    "code": "om",
                    "name": "Oromo"
                },
                {
                    "code": "os",
                    "name": "Ossetian Ossetic"
                },
                {
                    "code": "pi",
                    "name": "Pali"
                },
                {
                    "code": "ps",
                    "name": "Pashto Pushto"
                },
                {
                    "code": "fa",
                    "name": "Persian"
                },
                {
                    "code": "pl",
                    "name": "Polish"
                },
                {
                    "code": "pt",
                    "name": "Portuguese"
                },
                {
                    "code": "pa",
                    "name": "Punjabi Panjabi"
                },
                {
                    "code": "qu",
                    "name": "Quechua"
                },
                {
                    "code": "ro",
                    "name": "Romanian Moldavian Moldovan"
                },
                {
                    "code": "rm",
                    "name": "Romansh"
                },
                {
                    "code": "rn",
                    "name": "Rundi"
                },
                {
                    "code": "ru",
                    "name": "Russian"
                },
                {
                    "code": "se",
                    "name": "Northern Sami"
                },
                {
                    "code": "sm",
                    "name": "Samoan"
                },
                {
                    "code": "sg",
                    "name": "Sango"
                },
                {
                    "code": "sa",
                    "name": "Sanskrit"
                },
                {
                    "code": "sc",
                    "name": "Sardinian"
                },
                {
                    "code": "sr",
                    "name": "Serbian"
                },
                {
                    "code": "sn",
                    "name": "Shona"
                },
                {
                    "code": "sd",
                    "name": "Sindhi"
                },
                {
                    "code": "si",
                    "name": "Sinhala Sinhalese"
                },
                {
                    "code": "sk",
                    "name": "Slovak"
                },
                {
                    "code": "sl",
                    "name": "Slovenian"
                },
                {
                    "code": "so",
                    "name": "Somali"
                },
                {
                    "code": "st",
                    "name": "Southern Sotho"
                },
                {
                    "code": "es",
                    "name": "Spanish Castilian"
                },
                {
                    "code": "su",
                    "name": "Sundanese"
                },
                {
                    "code": "sw",
                    "name": "Swahili"
                },
                {
                    "code": "ss",
                    "name": "Swati"
                },
                {
                    "code": "sv",
                    "name": "Swedish"
                },
                {
                    "code": "tl",
                    "name": "Tagalog"
                },
                {
                    "code": "ty",
                    "name": "Tahitian"
                },
                {
                    "code": "tg",
                    "name": "Tajik"
                },
                {
                    "code": "ta",
                    "name": "Tamil"
                },
                {
                    "code": "tt",
                    "name": "Tatar"
                },
                {
                    "code": "te",
                    "name": "Telugu"
                },
                {
                    "code": "th",
                    "name": "Thai"
                },
                {
                    "code": "bo",
                    "name": "Tibetan"
                },
                {
                    "code": "ti",
                    "name": "Tigrinya"
                },
                {
                    "code": "to",
                    "name": "Tonga (Tonga Islands)"
                },
                {
                    "code": "ts",
                    "name": "Tsonga"
                },
                {
                    "code": "tn",
                    "name": "Tswana"
                },
                {
                    "code": "tr",
                    "name": "Turkish"
                },
                {
                    "code": "tk",
                    "name": "Turkmen"
                },
                {
                    "code": "tw",
                    "name": "Twi"
                },
                {
                    "code": "ug",
                    "name": "Uighur Uyghur"
                },
                {
                    "code": "uk",
                    "name": "Ukrainian"
                },
                {
                    "code": "ur",
                    "name": "Urdu"
                },
                {
                    "code": "uz",
                    "name": "Uzbek"
                },
                {
                    "code": "ve",
                    "name": "Venda"
                },
                {
                    "code": "vi",
                    "name": "Vietnamese"
                },
                {
                    "code": "vo",
                    "name": "Volapük"
                },
                {
                    "code": "wa",
                    "name": "Walloon"
                },
                {
                    "code": "cy",
                    "name": "Welsh"
                },
                {
                    "code": "wo",
                    "name": "Wolof"
                },
                {
                    "code": "xh",
                    "name": "Xhosa"
                },
                {
                    "code": "yi",
                    "name": "Yiddish"
                },
                {
                    "code": "yo",
                    "name": "Yoruba"
                },
                {
                    "code": "za",
                    "name": "Zhuang Chuang"
                },
                {
                    "code": "zu",
                    "name": "Zulu"
                }
            ];

            const name = locales.filter(lang => lang.code == code);
            if (name && name.length > 0) return name[0].name;
            return null;
        },
        spelledRupiah(payload) {

            payload = String(payload);
            let number = new Array('0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0');
            let word = new Array('', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan');
            let level = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');

            let payload_length = payload.length;
            let sentence = "";
            let subSentence = "";
            let word1 = "";
            let word2 = "";
            let word3 = "";
            let i = 0;
            let j = 0;

            if (payload_length > 15) {
                sentence = "N/A";
                return sentence;
            }

            for (i = 1; i <= payload_length; i++) {
                number[i] = payload.substr(-(i), 1);
            }

            i = 1;
            j = 0;
            sentence = "";

            while (i <= payload_length) {
                subSentence = "";
                word1 = "";
                word2 = "";
                word3 = "";

                if (number[i + 2] != "0") {
                    if (number[i + 2] == "1") {
                        word1 = "Seratus";
                    } else {
                        word1 = word[number[i + 2]] + " Ratus";
                    }
                }

                if (number[i + 1] != "0") {
                    if (number[i + 1] == "1") {
                        if (number[i] == "0") {
                            word2 = "Sepuluh";
                        } else if (number[i] == "1") {
                            word2 = "Sebelas";
                        } else {
                            word2 = word[number[i]] + " Belas";
                        }
                    } else {
                        word2 = word[number[i + 1]] + " Puluh";
                    }
                }

                if (number[i] != "0") {
                    if (number[i + 1] != "1") {
                        word3 = word[number[i]];
                    }
                }

                if ((number[i] != "0") || (number[i + 1] != "0") || (number[i + 2] != "0")) {
                    subSentence = word1 + " " + word2 + " " + word3 + " " + level[j] + " ";
                }

                sentence = subSentence + sentence;
                i = i + 3;
                j = j + 1;

            }

            if ((number[5] == "0") && (number[6] == "0")) {
                sentence = sentence.replace("Satu Ribu", "Seribu");
            }

            return (sentence.trim().replace(/\s{2,}/g, ' ')) + " Rupiah";
        },
        formatDate(payload) {
            const dateObject = new Date(payload);

            // Format the date manually
            const day = dateObject.getDate();
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const month = monthNames[dateObject.getMonth()];
            const year = dateObject.getFullYear();

            // Build the formatted date string
            return `${day}-${month}-${year}`;
        },
        formatDateWithoutDash(payload) {
            const dateObj = new Date(payload);
            const year = dateObj.getFullYear();
            let month = (dateObj.getMonth() + 1).toString();
            let day = dateObj.getDate().toString();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return `${year}${month}${day}`
        },
        formatCategory(payload) {
            const recordNames = payload.map(record => record.name);

            if (recordNames.length === 1) {
                return recordNames[0];
            } else if (recordNames.length === 2) {
                return recordNames.join(' & ');
            } else if (recordNames.length > 2) {
                const lastTwoRecords = recordNames.slice(-2).join(' & ');
                const remainingCount = recordNames.length - 2;
                return `${recordNames.slice(0, -2).join(', ')}, ${lastTwoRecords} & ${remainingCount} more`;
            }
            return '';
        },
        generateDecimalStep(decimalPlaces = 0) {
            if (typeof decimalPlaces !== 'number' || decimalPlaces < 0) {
                throw new Error('Decimal places must be a non-negative number');
            }

            const step = 1 / Math.pow(10, decimalPlaces);

            return step;
        },
        average(...numbers) {
            if (numbers.length === 0) {
                throw new Error('At least one number is required to calculate the average');
            }

            const sum = numbers.reduce((acc, num) => acc + parseFloat(num), 0);
            const average = sum / numbers.length;

            return average;
        },
        generateCode(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                result += characters.charAt(randomIndex);
            }

            return result;
        },
        calculateAge(birthday) {
            const birthDate = new Date(birthday);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        calculateDays(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
        genereteDatesInRange(startDate, endDate) {
            const dates = [];
            const start = new Date(startDate);
            const end = new Date(endDate);
            while (start <= end) {
                dates.push(new Date(start).toISOString().slice(0, 10));
                start.setDate(start.getDate() + 1);
            }
            return dates;
        },
        calculateDuration(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays + 1;
        },
        taxNumberFormat(value) {
            if (!value) return null;
            value = value.replace(/[A-Za-z\W\s_]+/g, '');
            let split = 6;
            const dots = [];

            for (let i = 0, len = value.length; i < len; i += split) {
                split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
                dots.push(value.substr(i, split));
            }

            const temp = dots.join('.');
            return temp.length > 12 ? `${temp.substr(0, 12)}-${temp.substr(12, 7)}` : temp;
        },
        secondToText(seconds) {
            const { t } = useI18n();
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);

            let formattedTime = '';
            if (hours > 0) {
                formattedTime += `${hours} ${t('Hours')} `;
            }
            if (minutes > 0 || hours === 0) {
                formattedTime += `${minutes} ${t('Minutes')}`;
            }

            return formattedTime.trim();
        },
        slugToCamelCase(slug) {
            return slug.toLowerCase().replace(/-([a-z])/g, function (match, group1) {
                return group1.toUpperCase();
            });
        },
        snakeToCamelCase(slug) {
            return slug.toLowerCase().replace(/_([a-z])/g, function (match, group1) {
                return group1.toUpperCase();
            });
        },
        toSlug(str) {
            if (str == null) return '';
            str = str.replaceAll(' ', '_');

            return str.toLowerCase();
        },

        isValidDateTime(datetime) {
            // Regular expression to match the datetime format
            const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\+\d{2}:\d{2}|\+\d{2})$/;

            if (!regex.test(datetime)) {
                return false;
            }

            const date = new Date(datetime);
            return !isNaN(date.getTime());
        },

        formatIfDateTime(datetime) {
            if (!this.isValidDateTime(datetime)) {
                return datetime;
            }

            const date = new Date(datetime);
            const pad = (num) => String(num).padStart(2, '0');

            const year = date.getFullYear();
            const month = pad(date.getMonth() + 1);
            const day = pad(date.getDate());
            const hours = pad(date.getHours());
            const minutes = pad(date.getMinutes());
            const seconds = pad(date.getSeconds());

            const offset = datetime.slice(19);
            const formattedOffset = offset.length === 3 ? `${offset}:00` : offset;

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} (${formattedOffset})`;
        },
        formatDateYMD(datetime) {
            const date = new Date(datetime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
});

// make sure to pass the right store definition, `useHelperStore` in this case.
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHelperStore, import.meta.hot))
}
