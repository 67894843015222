<script>
export default {
    setup() {
        const auth = useAuthStore();
        const layout = useLayoutStore();
        const config = useConfigurationStore();
        return { auth, layout, config };
    },
    computed: {
        ...mapGetters(useAuthStore, ["hasSession"]),
        ...mapGetters(useResponseStore, ["htmlError", "errors"]),
        year() {
            return new Date().getFullYear();
        },
    },
    methods: {
        isActive(payload) {
            return this.$route.path.includes(payload);
        },
    },
};
</script>

<template>
    <div class="uk-background-default">
        <section
            class="uk-section uk-padding-remove-vertical uk-box-shadow-medium"
            style="background-color: #fff; z-index: 1001"
            uk-sticky
        >
            <div class="uk-container">
                <div class="uk-grid uk-margin-remove-top" uk-grid>
                    <div class="uk-width-expand uk-hidden@m">
                        <div class="uk-flex uk-flex-middle">
                            <RouterLink to="/" class="uk-logo uk-navbar-item">
                                <img
                                    src="/images/logo.png"
                                    class="ids-home-logo"
                                    alt="logo"
                                />
                            </RouterLink>
                        </div>
                    </div>
                    <div class="uk-width-medium uk-visible@m">
                        <div class="uk-flex uk-flex-middle uk-padding-small">
                            <RouterLink to="/" class="uk-logo uk-navbar-item">
                                <img
                                    src="/images/logo.png"
                                    class="ids-home-logo"
                                    alt="logo"
                                />
                            </RouterLink>
                        </div>
                    </div>
                    <div
                        class="uk-width-expand uk-flex uk-flex-middle uk-visible@m"
                    >
                        <nav
                            class="uk-width-expand uk-navbar-container uk-flex uk-navbar-transparent uk-flex-center"
                            uk-navbar
                        >
                            <ul class="uk-navbar-nav">
                                <li
                                    :class="{
                                        'uk-active': this.$route.path == '/',
                                    }"
                                >
                                    <RouterLink class="uk-text-center" to="/">
                                        {{ $t("Home") }}
                                    </RouterLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div
                        class="uk-witdh-auto uk-flex uk-flex-middle uk-hidden@m"
                    >
                        <div class="uk-float-right uk-flex uk-flex-middle">
                            <div class="uk-inline">
                                <button
                                    class="uk-button uk-button-default"
                                    type="button"
                                >
                                    <span uk-navbar-toggle-icon></span>
                                </button>
                                <div
                                    uk-dropdown="mode: click;"
                                    class="uk-width-large"
                                >
                                    <ul
                                        class="uk-nav-primary uk-nav-parent-icon"
                                        uk-nav
                                    >
                                        <li>
                                            <RouterLink to="/">{{
                                                $t("Home")
                                            }}</RouterLink>
                                        </li>
                                    </ul>
                                    <template v-if="!hasSession">
                                        <RouterLink
                                            to="/auths/login"
                                            class="uk-button uk-button-default uk-border-rounded uk-align-center uk-margin-remove uk-modal-close"
                                        >
                                            Login
                                        </RouterLink>
                                    </template>
                                    <RouterLink
                                        v-else
                                        to="/dashboard"
                                        class="uk-button uk-button-primary uk-border-rounded uk-align-center uk-margin-small-bottom uk-modal-close"
                                    >
                                        Dashboard
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="uk-width-auto uk-flex uk-flex-middle uk-flex-center uk-visible@m"
                    >
                        <div class="uk-flex uk-flex-right" v-if="!hasSession">
                            <RouterLink
                                to="/auths/login"
                                class="uk-button uk-button-primary uk-button-small uk-border-rounded"
                            >
                                Login
                            </RouterLink>
                        </div>
                        <div v-else class="uk-flex uk-flex-right">
                            <RouterLink
                                to="/dashboard"
                                class="uk-button uk-button-primary uk-button-small uk-border-rounded"
                            >
                                Dashboard
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <RouterView />

        <!--FOOTER-->
        <PartialFooter />
        <!--/FOOTER-->
    </div>
</template>

<style scoped>
.splash-screen {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.splash-image-animation {
    max-width: 60%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
</style>
